import React from 'react';
import { useToast } from '@chakra-ui/react';

import useQueryString from './useQueryString';
import useLang from './useLang';

const LOCALE = {
  id: `Oops, saat ini Anda menggunakan mode <b>(Coba Link)</b>. Mohon untuk <b>Tidak memberikan link ini kepada tamu</b>, Gunakan button <b>(Kirim Link)</b> untuk mengirim link kepada tamu.`,
  en: `Oops, you are currently using <b>(Coba Link)</b>. Please <b>do not provide this link to guests</b>, use the <b>(Kirim Link)</b> button to send the link to guests.`,
};

/**
 * Custom hook to return coba link flag
 * @return {string}
 */
function useCobaLink() {
  const toast = useToast();
  const { getQueryValue } = useQueryString();
  const lang = useLang();

  const query = getQueryValue('ref');
  const isCobaLink = query === 'coba';

  const handleShowToasterError = (callback) => {
    if (isCobaLink) {
      toast({
        isClosable: true,
        status: 'warning',
        duration: null,
        description: <p dangerouslySetInnerHTML={{ __html: LOCALE[lang] }} />,
      });
    } else {
      callback();
    }
  };

  return { isCobaLink, onValidateCobaLink: handleShowToasterError };
}

export default useCobaLink;
