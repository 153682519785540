import { useCallback, useState } from 'react';
import { API_HOSTNAME } from '@/constants/api';

/**
 * custom hooks to mutate data
 * @param {string} HOSTNAME
 * @param {string} TABLE_NAME
 */
function useMutation(TABLE_NAME, ACTION) {
  const [loading, setLoading] = useState(false);

  /**
   * @function
   */
  const handleMutate = useCallback(async (options) => {
    try {
      setLoading(true);
      const config = `table_name=${TABLE_NAME}&action=${ACTION}`;
      const rawResult = await fetch(`${API_HOSTNAME}?${config}&${options}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'POST',
      });

      const response = await rawResult.json();
      setLoading(false);
      return response;
    } catch (e) {
      console.error(e);
      setLoading(false);
      return { success: false, error: e };
    }
  }, []);

  return { onMutation: handleMutate, loading };
}

export default useMutation;
